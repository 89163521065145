export interface UnitSettings {
    autoCloseAfterTime: boolean;
    autoCloseTimeInHours: number;
    autoCloseStatus2: boolean;
    autoDeleteApager: boolean;
    autoDeleteApagerTimeInHours: number;
    disabled: boolean;
    useFeedback: boolean;
    customFeedbackId: string;
    hasCustomFeedback: boolean;
    tracing: boolean;

    addressCalculationActive: boolean;
    addressCalculationModes: EAddressCalculationMode[];
    addressCalculationField: string;
    overrideDefaultDoubleAlarmTime: boolean;
    doubleAlarmTime: number;
    filtered: boolean;
    selectedSplitter: Splitter;
    buildingCalculationModeCheck: EBuildingCalculationModeCheck;
}

export enum EAddressCalculationMode {
    TEXT_PATTERN,
    COORDINATES_DEZ,
    COORDINATES_HEX,
    COORDINATES_GK,
    STREET_DICTIONARY,
    CODE,
    BUILDING
}

export enum EBuildingCalculationModeCheck {
    CONTAINS = 'CONTAINS',
    EQUALS = 'EQUALS'
}

export interface Unit {
    userId: string;
    id: string;
    name: string;
    code: string;
    settings: UnitSettings;
    type: EUnitType;
    hasQuickEdit: boolean;
    hasTracings: boolean;
}

export enum EUnitType {
    DEFAULT = 'DEFAULT',
    SAMMEL = 'SAMMEL',
    EXPRESS = 'EXPRESS',
    WACHALARM_PRE = 'WACHALARM_PRE',
    WACHALARM_POST = 'WACHALARM_POST',
    STATUS = 'STATUS',
    AVAILABILITY_RULE = 'AVAILABILITY_RULE',
    CUSTOM = 'CUSTOM'
}

export interface UnitFilterResponse {
    filter?: string,
    numberOfUsers: number,
    users: UnitFilterEntry[]
}

export interface DeactivatedUnits {
    deactivatedUnits: number,
    allUnits: number
}

export interface UnitFilterEntry {
    username: string,
    userId: string,
    units: Unit[]
}

export class Splitter {
    public id: string;
    public name: string;
    public version: string;
    public author: string;
}
